import { useEffect } from 'react';

const IndexPage = () => {
  useEffect(() => {
    window.location.href = 'https://institute.mindbodygreen.com/';
  }, []);

  return null;
};

export default IndexPage;
